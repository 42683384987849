<template>
  <p class="crumbs">
    您当前的位置：<span
      class="pointer"
      @click="navgateTo(item)"
      v-for="(item, index) in routerList"
      :key="index"
      >> {{ item.meta.name }}
    </span>
  </p>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      routerList: [],
    };
  },
  created() {
    this.routerList = this.$route.matched;
  },
  watch: {
    $route(to) {
      this.routerList = to.matched;
    },
  },
  methods: {
    navgateTo(item) {
      this.$router.push(item.path);
    },
  },
};
</script>
<style lang="less" scoped>
.crumbs {
  padding-right: 40px;
  float: right;
  font-size: 14px;
  color: #777;
}
</style>
