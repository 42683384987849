<template>
  <div class="footer">
    <div class="footer-info">
      <p>地址：湖北省武汉市武昌区白沙洲街丰收村2号新鑫佳苑小区12栋1层1室-1号</p>
      <p>联系电话：18574696888</p>
      <p>Copyright ©湖北万康大药房有限公司版权所有</p>
      <a
        href="https://beian.miit.gov.cn/#/Integrated/index"
        target="_blank"
        class="icp"
      >
        ICP备案编号：
      </a>
      <p>互联网药品信息服务资格证书：</p>
    </div>
    <div class="tips">
      承诺本网站不涉及药品交易、不撮合药品交易、不发布麻醉药品、精神药品、医疗用毒性药品、放射性药品、性药品、戒毒药品和医疗机构制剂的产品信息。
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>
<style lang="less" scoped>
.footer {
  font-size: 14px;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  width: 100%;
  padding-top: 70px;
  margin-top: 40px;
  float: left;
  background: url(data:image/jpeg;base64,/9j/4QAYRXhpZgAASUkqAAgAAAAAAAAAAAAAAP/sABFEdWNreQABAAQAAAA8AAD/4QNvaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLwA8P3hwYWNrZXQgYmVnaW49Iu+7vyIgaWQ9Ilc1TTBNcENlaGlIenJlU3pOVGN6a2M5ZCI/PiA8eDp4bXBtZXRhIHhtbG5zOng9ImFkb2JlOm5zOm1ldGEvIiB4OnhtcHRrPSJBZG9iZSBYTVAgQ29yZSA1LjMtYzAxMSA2Ni4xNDU2NjEsIDIwMTIvMDIvMDYtMTQ6NTY6MjcgICAgICAgICI+IDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+IDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiIHhtbG5zOnhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdFJlZj0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlUmVmIyIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bXBNTTpPcmlnaW5hbERvY3VtZW50SUQ9InhtcC5kaWQ6M0YzMjQ5QkY3QzgxRTMxMUE5N0U4REIxMEFEOTVGRjAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NkM2NURDOEY4MzMxMTFFMzlERDdCNzRDQkZBRUU5NzEiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NkM2NURDOEU4MzMxMTFFMzlERDdCNzRDQkZBRUU5NzEiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpFQzFEMERDRTE2ODNFMzExOEQ0MDhEQUExOEYzNUUxMiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDozRjMyNDlCRjdDODFFMzExQTk3RThEQjEwQUQ5NUZGMCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pv/uAA5BZG9iZQBkwAAAAAH/2wCEAAYEBAQFBAYFBQYJBgUGCQsIBgYICwwKCgsKCgwQDAwMDAwMEAwODxAPDgwTExQUExMcGxsbHB8fHx8fHx8fHx8BBwcHDQwNGBAQGBoVERUaHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fHx8fH//AABEIANUACAMBEQACEQEDEQH/xABlAAEAAwAAAAAAAAAAAAAAAAAAAgMGAQEAAgMBAAAAAAAAAAAAAAAAAgMBBAUGEAEAAAMJAAAAAAAAAAAAAAAAAQNT0QISotIjkwUVEQEAAwADAAAAAAAAAAAAAAAAEVECARIT/9oADAMBAAIRAxEAPwDPbdGTxS9Lv+eaeE9dWJqwAAAGe9Ds62W7YqnltdM0swQShXKTKIAAAAAAAAAAAAAAAAAD/9k=)
    repeat-x top;
  color: #70abad;
  .footer-info {
    text-align: center;
    padding-bottom: 10px;

    p {
      line-height: 26px;
    }

    a {
      color: #70abad;
    }
  }
  .tips {
    text-align: center;
    color: #70abad;
    line-height: 50px;
    height: 50px;
    background-color: #fff;
    word-wrap: none;
  }
}
</style>
