import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'
import ypzs from '../views/ypzs'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
    meta: {
      name: '首页'
    }
  },
  {
    path: '/home',
    name: 'home',
    component: home,
    meta: {
      name: '首页'
    }
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "ypzs" */ '../views/contact/index.vue'),
    meta: {
      name: '联系我们'
    }
  },
  {
    path: '/ypzs',
    name: '/ypzs',
    component: ypzs,
    meta: {
      name: '药品展示'
    },
    children: [
      {
        path: '',
        meta: {
          name: '列表'
        },
        component: () => import(/* webpackChunkName: "ypzs" */ '../views/yplb.vue')
      },
      {
        path: 'detail',
        meta: {
          name: '详情'
        },
        component: () => import(/* webpackChunkName: "ypzs" */ '../views/ypxq.vue')
      },
    ],

  },
  {
    path: '/ylqx',
    name: '/ylqx',
    component: () => import(/* webpackChunkName: "ypzs" */ '../views/equipment/index.vue'),
    meta: {
      name: '器械展示'
    },
    children: [
      {
        path: '',
        meta: {
          name: '列表'
        },
        component: () => import(/* webpackChunkName: "ypzs" */ '../views/equipment/list.vue'),
      },
      {
        path: 'detail',
        meta: {
          name: '详情'
        },
        component: () => import(/* webpackChunkName: "ypzs" */ '../views/equipment/detail.vue'),
      },
    ],

  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
