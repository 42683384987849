<template>
  <div id="app">
    <layout>
      <router-view />
    </layout>
  </div>
</template>
<script>
import layout from "./components/layout.vue";
export default {
  components: {
    layout,
  },
};
</script>
<style lang="less" src="./style/index.less">
#app {
  // width: 100vw;
}
</style>
