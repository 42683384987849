export const list = [
    {
        "name": "脾胃舒丸",
        "size": "9g*10丸/板/盒",
        "factory": "吉林四环澳康药业有限公司",
        "id": "国药准字Z22020524",
        "type": "中成药",
        "img": [
            require("@/assets/yaowu/四环_脾胃舒丸1.png"),
            require("@/assets/yaowu/四环_脾胃舒丸2.png"),
            require("@/assets/yaowu/四环_脾胃舒丸3.png"),
            require("@/assets/yaowu/四环_脾胃舒丸4.png"),
            require("@/assets/yaowu/四环_脾胃舒丸批件.png"),
            require("@/assets/yaowu/四环_脾胃舒丸1.png"),


        ]
    },
    {
        "name": "潞党参口服液",
        "size": "10ml*12支/盒",
        "factory": "山西正来制药有限公司",
        "id": "国药准字Z20059002",
        "type": "中成药",
        "img": [
            require("@/assets/yaowu/正来潞党_潞党参口服液1.png"),
            require("@/assets/yaowu/正来潞党_潞党参口服液2.png"),
            require("@/assets/yaowu/正来潞党_潞党参口服液3.png"),
            require("@/assets/yaowu/正来潞党_潞党参口服液批件.png"),
        ]
    },
    {
        "name": "妇科养荣胶囊",
        "size": "0.35g*12粒*2板/盒",
        "factory": "陕西得安制药有限责任公司",
        "id": "国药准字Z20173027",
        "type": "中成药",
        "img": [
            require("@/assets/yaowu/西京_妇科养荣胶囊1.png"),
            require("@/assets/yaowu/西京_妇科养荣胶囊2.png"),
            require("@/assets/yaowu/西京_妇科养荣胶囊3.png"),
            require("@/assets/yaowu/西京_妇科养荣胶囊批件.png"),

        ]
    },
    {
        "name": "儿康宁糖浆",
        "size": "100ml*3瓶/盒",
        "factory": "太极集团重庆涪陵制药厂有限公司",
        "id": "国药准字Z50020541",
        "type": "中成药",
        "img": [
            require("@/assets/yaowu/太极_儿康宁糖浆1.jpeg"),
            require("@/assets/yaowu/太极_儿康宁糖浆2.jpeg"),
            require("@/assets/yaowu/太极_儿康宁糖浆3.jpeg"),
            require("@/assets/yaowu/太极_儿康宁糖浆4.jpeg"),
            require("@/assets/yaowu/太极_儿康宁糖浆批件.png"),

        ]
    },

]

